<template>
  <div class="vld-parent">
    <loading :active="isLoading"
    :can-cancel="true"
    :loader="bars"
    :opacity='0.1'
    :on-cancel="onCancel"
    :is-full-page="fullPage"><img src="/image/loading.svg"></loading>
  </div>
  <main class="layout-main -home">
    <div class="inner">
      <!--div class="banner"><router-link to="/works/detail/241"><img src="@/assets/image/home/banner.png" alt=""></router-link></div-->
      <div class="layout-firstview">
        <div class="catchcopy">他とちょっと違う、<br>コミュニケーションデザインを。</div>
        <div class="carousel-block -gallery">
          <swiper effect="fade" :speed="500" :slidesPerView="1" :slidesPerGroup="1" :crossFade="true" :loop="true" :loopFillGroupWithBlank="true" :navigation="true" :autoplay="{'delay': 3500,'disableOnInteraction': false}" @slideChange="onSlideChange" class="mySwiper">
            <swiper-slide :style="{backgroundImage: 'url(' + require('@/assets/image/home/slide/06.jpg') + ')' }" class="-s06"><div class="gag -s06"><img src="@/assets/image/home/slide/fukidashi/06.png" alt=""></div></swiper-slide>
            <swiper-slide :style="{backgroundImage: 'url(' + require('@/assets/image/home/slide/02.jpg') + ')' }" class="-s02"><div class="gag -s02"><img src="@/assets/image/home/slide/fukidashi/02.png" alt=""></div></swiper-slide>
            <swiper-slide :style="{backgroundImage: 'url(' + require('@/assets/image/home/slide/03.jpg') + ')' }" class="-s03"><div class="gag -s03"><img src="@/assets/image/home/slide/fukidashi/03.png" alt=""></div></swiper-slide>
            <swiper-slide :style="{backgroundImage: 'url(' + require('@/assets/image/home/slide/05.jpg') + ')' }" class="-s05"><div class="gag -s05"><img src="@/assets/image/home/slide/fukidashi/05.png" alt=""></div></swiper-slide>
          </swiper>
        </div>
        <div class="news-line"><div class="container-wrapper" v-if="news.data">
          <router-link :to="{ name: 'NewsDetail', params: { id: news.data[0].id }}" class="item">
            <dl><dt>{{setNewsDate(news.data[0].date )}}</dt><dd><p>{{news.data[0].title.rendered}}</p></dd></dl></router-link>
          <div class="more"><router-link to="/news">MORE</router-link></div>
        </div></div>
      </div>

      <section class="carousel-block -works">
        <router-link to="/works" ><h1 class="heading-page -home"><img src="@/assets/image/title/works.svg" alt="Works"><span class="sub">制作実績</span></h1></router-link>

        <div class="container-wrapper container-pad -works -nopadding ">
          <transition-group name="work" tag="ul" class="grid-works "
            @before-enter="beforeEnter"
            @after-enter="afterEnter"
            @enter-cancelled="afterEnter"
            @before-leave="beforeLeave"
             ref="worklist"
            >


             <li v-for="(post,index) in works.data" :key="index" :data-index="index">
              <router-link :to="{ name: 'WorkDetail', params: { id: post.id }}">
              <div class="card-block -hasbg">
                <div class="meta"><time v-if="post.date">{{ setDate(post.date ) }}</time></div>
                <div class="thumb" v-if="post.acf"><img src="@/assets/image/trans.png" alt="" :style="{backgroundImage:'url(' + post.acf.w_main_image.url + ')' }"></div>
                <div class="numbertitle" v-if="post.title">{{post.title.rendered}}</div>
                
              </div>
              </router-link>
              <ul class="meta-cat">
                <template  v-for="(cat, idx ) in post.category_detail" :key="idx">
                <li v-if="cat.me.parent > 0"><span class="icon" :class='`${cat.me.category_nicename}`'>{{cat.me.name}}</span></li>
                </template>
              </ul>
            </li>
          </transition-group>
        </div>


        <!--
        <swiper :effect="fade" :slidesPerView="1.5" :centeredSlides="true" :spaceBetween="50" :loop="true" :loopFillGroupWithBlank="true" :navigation="true" :autoplay="{'delay': 2000,'disableOnInteraction': true}" :breakpoints="{
              '640': {
                'slidesPerView': 2.8,
                'spaceBetween': 50
              }
            }"
          class="mySwiper">

          <swiper-slide v-for="(work,index) in works.data" :key="index" :data-index="index">
            <router-link :to="{ name: 'WorkDetail', params: { id: work.id }}">
            <div class="card-block">
              <div class="thumb"><img src="@/assets/image/trans.png" alt="" :style="{backgroundImage:'url(' + work.acf.w_main_image.url + ')' }"></div>
              <div class="numbertitle">{{work.title.rendered}}</div>
              <div class="meta"><time>{{ setDate(work.title.modified ) }}</time></div>
            </div>
            </router-link>
          </swiper-slide>

        </swiper>
        -->
        <div class="container-button"><router-link to="/works" class="button-link"><span class="-more">MORE</span></router-link></div>
      </section>

      <section class="container-bg">
          <div class="container-wrapper -pad">
            <div class="grid-block -cols2 -homeindex">
              <div>
                <router-link to="/service">
                  <div class="thumb"><img src="@/assets/image/home/01.png" alt=""></div>
                  <h2 class="heading-block"><img src="@/assets/image/title/service.svg" alt="Service"><span class="sub">事業内容</span></h2>
                  <p>One Betterは「一枚上手」な発想・企画・繋がりで、ワクワクするコミュニケーションプランの支援を行なっています。</p>
                </router-link>
              </div>
              <div>
                <router-link to="/message">
                  <div class="thumb"><img src="@/assets/image/home/02.png" alt=""></div>
                    <h2 class="heading-block"><img src="@/assets/image/title/message.svg" alt="Message"><span class="sub">メッセージ</span></h2>
                    <p>一枚上手なライフスタイルで、一枚上手な企画や発想で、常に緊張している世の中の『コリ』を少しでも解していきます。</p>
                </router-link>
              </div>
              <div>
                <router-link to="/company">
                  <div class="thumb"><img src="@/assets/image/home/03.png" alt=""></div>
                  <h2 class="heading-block"><img src="@/assets/image/title/company.svg" alt="Company"><span class="sub">企業概要</span></h2>
                  <p>茅ヶ崎から、発信しております。</p>
                </router-link>
              </div>
              <div>
                <router-link to="/contact">
                <div class="thumb"><img src="@/assets/image/home/04.png" alt=""></div>
                <h2 class="heading-block"><img src="@/assets/image/title/contact.svg" alt="Contact"><span class="sub">お問い合わせ</span></h2>
                <p>One Betterへのつぶやきはこちらから。</p>
                </router-link>
              </div>
            </div>
            <!--HelloWorld msg="Welcome to Your Vue.js App"/-->
          </div>
      </section>

    </div>
  </main>
</template>

<script>
//import HelloWorld from '@/components/HelloWorld.vue'
//import "vue3-carousel/dist/carousel.css";
//import { Carousel, Navigation, Slide } from "vue3-carousel";

import { onMounted, onUpdated, onUnmounted,reactive, ref } from 'vue'
import axios from "axios"
import moment from 'moment'
import SwiperCore, {
  Pagination,
  Navigation,
  EffectFade,
  Autoplay
} from 'swiper/core';
import { Swiper, SwiperSlide } from 'swiper/vue'
require('swiper/swiper.scss')
require( "swiper/components/pagination/pagination.min.css")
require( "swiper/components/navigation/navigation.min.css")
require( 'swiper/components/effect-fade/effect-fade.scss')
import Loading from 'vue-loading-overlay';
require( 'vue-loading-overlay/dist/vue-loading.css')

SwiperCore.use([
  Pagination,
  Navigation,
  EffectFade,
  Autoplay
])

export default {
  name: 'Home',
  components: {
    Swiper,
    SwiperSlide,
    Loading
  },
  setup(){
    const API_HOST = process.env.VUE_APP_APIRESTHOST
    const isLoading = ref(false)
    const works = reactive({
      data:null,
    })
    const news = reactive({
      data:null,
    })

    const setDate = ((date) => {
      //return moment(date).format('YYYY.MM.DD')
      return moment(date).format('YYYY')
    })
    const setNewsDate = ((date) => {
      return moment(date).format('YYYY.MM.DD')
    })

    const getAll = () => {

      let worksURL = '//' + API_HOST + '/c/wp-json/wp/v2/posts?order=asc&categories=2&per_page=16'
      let newsURL = '//' + API_HOST + '/c/wp-json/wp/v2/posts?order=asc&categories=1&per_page=1'

      const req_works = axios.get(worksURL)
      const req_news = axios.get(newsURL)

      isLoading.value = true
      axios.all([ req_works, req_news ])
      .then( response => {
        works.data = response[0].data
        news.data = response[1].data
        //console.log(works.data);

        setTimeout(() => {
          isLoading.value = false
        }, 300)
      })
    }

    onMounted(() => {
      //console.log(getWorkItem())
      //console.log(getNewsItem())

      getAll()

    })
    onUpdated(() => {
    })
    onUnmounted(() => {
    })
    return{
      works,
      setDate,
      news,
      isLoading,
      setNewsDate
      //isList
    }
  },

}
</script>

<style lang="scss">
.page-home .vld-parent{
  z-index:100000
}
.layout-main.-home{
  padding-top:0
}
.banner{
  position:fixed;
  bottom:100px;
  right:40px;
  width:240px;
  z-index:1000;
  border-radius:8px;
  overflow:hidden;
  box-shadow:0 0 5px 3px rgba(#000,0.2);
  @media screen and (max-width: 480px) {
    width:150px;
    right:0px;
    bottom:210px;
    border-radius:8px 0 0 8px;
  }
  img{
    display:block;
    width:100%;
  }
}
.page-Home{
  .nav-global ul li a{
    color:#fff;
    &::after{
      background-color:#fff;
    }
  }
  .logo .white{
    display:block;
  }
  .logo .blue{
    display:none;
  }
}


</style>