<template>
  <div class="container-screen" ref="root" v-bind:class="{'status-nav':is_open}">
    <Header @nav_open="nav_open" @nav_close="nav_close" />
    <router-view v-slot="{ Component }">
      <component :is="Component" @scrollTo="scrollTo"></component>
    </router-view>
      <!--
      <router-view v-slot="{ Component }">
          <transition mode="out-in">
            <component :is="Component" @scrollTo="scrollTo"></component>
          </transition>
      </router-view>
    -->
    <Footer/>
  </div>

</template>


<script>
import { onMounted, onUpdated, onUnmounted, watch, ref } from 'vue'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
  components: {
    Header,
    Footer
  },
  setup() {
    const root = ref()
    const is_open = ref(false)

    const myresize = () => {
      //console.log('resize');
    }
    const myscroll = () => {
      //console.log('scroll ' + window.scrollY);
      if( window.scrollY > 0 ){
        document.body.classList.add('status-scroll')
      }else{
        document.body.classList.remove('status-scroll')
      }
    }
    const nav_open = () =>  {
      is_open.value = !is_open.value
    }
    const nav_close = () => {
      is_open.value = false
    }
    const scrollTo = (refName) => {
      const el = refName
      //el.scrollIntoView({ behavior: 'smooth'})

      setTimeout(function(){
        let offsetY = 68;
        let elPos = el.getBoundingClientRect().top;
        let offsetPos = elPos - offsetY;
        window.scrollTo({
          top: offsetPos,
          behavior: "smooth"
        });
      },100)
    }

    onMounted(() => {
      window.addEventListener('resize', myresize);
      window.addEventListener('scroll', myscroll);
    })
    onUpdated(() => {
      //console.log('APP updated!');
    })
    onUnmounted(() => {
      //console.log('APP unmounted!');
    })
    watch(() => {
      //console.log('APP watch')
    })
    return{
      root,
      nav_open,
      nav_close,
      is_open,
      scrollTo
    }
  }
}
</script>


<style lang="scss">
@import url('//use.typekit.net/ijl5nny.css');
@import "./assets/css/style.css";


/*
.carousel-block.-works .card-block .thumb{
 background-image:url(/image/loading.gif);
 background-repeat: no-repeat;
 background-size: 60px auto;
 background-position: 50%;
 background-color:#fff;
}
*/

</style>
