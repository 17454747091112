export default {
   created() {
   },
   mounted() {
   },
   updated(){
      /*
      const main = document.getElementsByClassName('layout-main');
      const header = document.getElementsByClassName('layout-header');
      if(main[0] && !main[0].style.paddingTop){
        setTimeout(function(){
          console.log('layout padding');
          main[0].style.paddingTop = header[0].getBoundingClientRect().height +'px';
        },10);
      }
      */
   },
   data() {
       return {
           serverPass: "https://localhost:10443/",
       }
   },
   methods: {
       logging() {
           console.log('logging from mixins.')
       }
   },

   computed: {
       twoBytwo() {
           return 2 * 2
       }
   }
}
