import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: {
      title: 'OneBetter | 一枚上手な企画や発想で',
      description: '他とちょっと違う、コミュニケーションデザインを。'
    },
    component: Home
  },
  {
    path: '/service',
    name: 'Service',
    meta: {
      title: 'Service 事業概要 | OneBetter', 
      description: 'One Betterは「一枚上手」な発想・企画・繋がりで、ワクワクするコミュニケーションプランの支援を行なっています。',
    },
    component: () => import( '../views/Service.vue')
  },
  {
    path: '/works',
    name: 'Works',
    meta: {
      title: 'Works 制作実績 | OneBetter' ,
      description: 'やったこと！やっていること！'
    },
    component: () => import( '../views/Works.vue'),
    /*
    children: [
      {
        path: '/works/detail/:id',
        name: 'WorkDetail',
        component: () => import('../views/WorkDetail.vue')
      }
    ]
*/
  },
  {
    path: '/works/:id',
    name: 'WorksCategory',
    meta: {
      title: 'Works 制作実績 | OneBetter',
      description: 'やったこと！やっていること！'
    },
    component: () => import( '../views/Works.vue'),

  },
  {
    path: '/works/detail/:id',
    name: 'WorkDetail',
    meta: {
      title: 'Works 制作実績 | OneBetter',
      description: 'やったこと！やっていること！'
    },
    component: () => import( '../views/WorkDetail.vue'),
  },
  {
    path: '/news',
    name: 'News',
    meta: {
      title: 'News お知らせ | OneBetter' ,
       description: 'お知らせ',
    },
    component: () => import( '../views/News.vue'),
    /*
    beforeEnter: (to, from) => {
        console.log(to)
        console.log(from)
    }*/
  },
  {
    path: '/news/detail/:id',
    name: 'NewsDetail',
    meta: {
      title: 'News お知らせ | OneBetter',
      description: 'お知らせ'
    },
    component: () => import( '../views/NewsDetail.vue'),
  },
  {
    path: '/message',
    name: 'Message',
    meta: {
      title: 'Message メッセージ | OneBetter',
      description: '変わることのない普遍的な感情や情緒に触れる様なコミュニケーションで、世間の「コリ」をほぐしたい。',
    },
    component: () => import( '../views/Message.vue')
  },
  {
    path: '/company',
    name: 'Company',
    meta: { 
      title: 'Company 企業概要 | OneBetter',
      description: '茅ヶ崎から'
    },
    component: () => import( '../views/Company.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    meta: { 
      title: 'Contact お問い合わせ | OneBetter',
      description: 'お問い合わせ'
    },
    component: () => import( '../views/Contact.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    meta: {
      title: 'NotFound ありまへん！ | OneBetter' ,
      description: 'Not Found'
    },
    component: () => import( '../views/NotFound.vue')
  }
]
const scrollBehavior = (to, from, savedPosition) => {
  if (savedPosition) {
    return new Promise((resolve) => {
      setTimeout(() => {
        //console.log(savedPosition)
        resolve(savedPosition)
      },2000)
    })
  } else {
    return { top: 0 }
  }
};

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior
})

const DEFAULT_TITLE = 'OneBetter'
router.afterEach((to ) => {
  document.title = to.meta.title || DEFAULT_TITLE
  document.body.className =  'page-' + to.name
  document.querySelector("meta[name='description']").setAttribute('content', to.meta.description )

  //console.log(to)
  window.gtag('config', 'UA-150940087-1', {'page_path': to.path});
})

router.beforeEach((to, from ) => {
  router.to = to
  router.from = from
  //if(from.name == 'WorkDetail' && to.name.indexOf( 'Work') >= 0 ){
  //}
})


router.resolve({
  name: 'NotFound',
  params: { pathMatch: ['not', 'found'] },
}).href // '/not/found'

export default router
