


import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
//import moment from 'vue-moment'
import VueAxios from 'vue-axios'
import layout from '@/mixin/layout.js'


//import { createMetaManager } from 'vue-meta'

const app = createApp(App)

//app.config.globalProperties.$httpd = axios;
//app.config.globalProperties.$moment = moment;
app.config.globalProperties.foo = 'bar'

app.mixin(layout)

const data = "テスト"
window.vueApp = app


//app.use(router,store,createMetaManager()).mount('#app')
app.use( router, store, VueAxios, axios, data ).mount('#app')


