
<template>
  <div class="layout-header" ref="me">
    <nav class="nav-global container-wrapper">
      <div class="logo"><router-link to="/"><img alt="OneBetter" src="@/assets/image/logo_b.svg" class="blue"><img alt="OneBetter" src="@/assets/image/logo.svg" class="white"></router-link></div>
      <ul class="menu">
        <li :class="{'-active': $route.path === '/'}"><router-link to="/" @click="close_nav">Home</router-link></li>
        <li :class="{'-active': $route.path === '/service'}"><router-link to="/service" @click="close_nav">Service</router-link></li>
        <li :class="{'-active':  $route.path.indexOf('/works') >= 0 }"  @click="close_nav"><router-link to="/works">Works</router-link></li>
        <li :class="{'-active': $route.path === '/message'}"><router-link to="/message" @click="close_nav">Message</router-link></li>
        <li :class="{'-active': $route.path === '/company'}"><router-link to="/company" @click="close_nav">Company</router-link></li>
        <li :class="{'-active': $route.path === '/contact'}"><router-link to="/contact" @click="close_nav">Contact</router-link></li>
      </ul>
      <div class="bg-shadow" @click="close_nav"></div>
    </nav>
    <div class="nav-sp" @click="click_burger">
      <span></span>
      <span></span>
      <span></span>
    </div>
  </div>

</template>

<script>
import { ref  } from 'vue'
export default {
  emits: ['nav_open','nav_close'],
  setup(props, { emit }) {
    const me = ref()
    const click_burger = () => {
      emit("nav_open" , true )
    }
    const close_nav = () => {
      emit("nav_close" )
    }
    return{
      click_burger,
      close_nav,
      me
    }
  }
}
</script>


